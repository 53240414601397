.email-success,
.email-failed,
.email-loading,
.success-msg,
.error-msg {
  text-align: center;
  padding: 10px;
  display: none;
}
.email-loading img {
  width: 20px;
  margin: 0 auto;
}
.email-failed .icon {
  font-size: 20px;
  position: relative;
  top: 5px;
}
.email-failed .icon,
.email-success .icon,
.error-msg .icon,
.success-msg .icon {
  font-size: 20px;
  position: relative;
  top: 2px;
}

.contact {
  &-form-box {
    padding: 40px;
    background-color: $bg-one;
    @include respond(laptop) {
      padding: 20px;
    }
    form {
      text-align: center;
    }
    input,
    textarea {
      width: 100%;
      border: none;
      padding: 10px;
      margin-bottom: 15px;
      background-color: $color-light;
      border-bottom: 2px solid transparent;
    }
    input:focus,
    textarea:focus {
      outline: none;
      border: none;
      border-bottom: 2px solid $color-primary;
    }
    textarea {
      min-height: 120px;
      transition: all 0.3s ease-in-out;
    }
  }
  &__address {
    height: 100%;
    background-color: $bg-one;
    iframe {
      width: 100%;
      height: 250px;
      padding-bottom: 20px;
    }
    &__content {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 30px;
        a {
          display: block;
          color: $color-secondary;
          &:hover {
            color: $color-primary;
          }
        }
        span {
          font-weight: 700;
          font-family: $font-heading;
          display: block;
          margin-bottom: 5px;
          text-transform: uppercase;
        }
      }
    }
  }
}
.contactv4 {
  &__content {
      li {
        list-style: none;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          padding-bottom: 20px;
        }
        svg {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          padding: 5px;
          background-color: $color-primary;
          color: $color-light;
          transition: all 0.5s;
          box-shadow: 3px 3px $color-secondary;
          font-size: 20px;
          margin-right: 20px;
          &:hover {
            background-color: $color-secondary;
            box-shadow: none;
          }
          @media screen and (max-width: 320px) {
            width: 30px;
            height: 30px;
            font-size: 15px;
          }
        }
      }
  }
}
.bg-one {
  .contact-form-box {
    background-color: white;
    input,
    textarea {
      background-color: $bg-one;
    }
  }
  .contact__address {
    background-color: white;
  }
}