.pricing {
  &__item {
    background-color: $bg-one;
    padding: 40px 50px;
    text-align: center;
    transition: 0.3s;
    &:hover {
      box-shadow: 5px 5px $color-secondary;
    }
    &.active {
      box-shadow: 5px 5px $color-secondary;
    }
    @include respond(laptop) {
      padding: 20px;
    }
    @include respond(tablet) {
      padding: 40px 50px;
    }
  }

  &__price {
    font-size: 40px;
  }
  &__list {
    padding-left: 0;
    margin: 40px 0;
    li {
      list-style: none;
      padding: 10px 0;
      &:not(:last-child) {
        border-bottom: 1px solid $color-primary;
      }
      svg {
        font-size: 20px;
        margin-right: 15px;
      }
    }
  }
}

.bg-one {
  .pricing__item {
    background-color: $color-light;
  }
  .pricing__list li:not(:last-child) {
    border-bottom: 1px solid $color-border;
  }
}
